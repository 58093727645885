import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Components
import LayoutPage from '../components/Layouts/LayoutPage';
import ContentModules from '../components/Content/ContentModules';

const ArchivePost = ({ pageContext }) => {
  const { restData } = pageContext;

  return (
    <LayoutPage
      backgroundImage={restData.acf.page_hero_bg}
      imageSlider={restData.acf.page_image_slider}
      imageSliderImages={restData.acf.page_image_slider_images}
      title={restData.title.rendered}
      parentPage="news"
      yoastMeta={restData.yoast_meta}
    >
      <main>
        <div className="content-modules">
          {restData.acf.content_modules.map((module) => {
            return <ContentModules key={shortid.generate()} module={module} />;
          })}
        </div>
      </main>
    </LayoutPage>
  );
};

export default ArchivePost;

ArchivePost.defaultProps = {
  pageContext: {
    restData: {
      acf: {
        content_modules: null,
        page_image_slider_images: null,
        page_image_slider: false,
      },
    },
  },
};

ArchivePost.propTypes = {
  pageContext: PropTypes.shape({
    restData: PropTypes.shape({
      acf: PropTypes.shape({
        content_modules: PropTypes.arrayOf(PropTypes.shape({})),
        page_hero_bg: PropTypes.shape({}).isRequired,
        page_image_slider: PropTypes.bool,
        page_image_slider_images: PropTypes.arrayOf(PropTypes.shape({})),
      }).isRequired,
      title: PropTypes.shape({
        rendered: PropTypes.string.isRequired,
      }),
      yoast_meta: PropTypes.shape({}).isRequired,
    }).isRequired,
  }),
};
